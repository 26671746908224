<script>
	import { ChevronLeftIcon, ChevronRightIcon } from "svelte-feather-icons";
	import { onMount, afterUpdate, createEventDispatcher } from "svelte";
	import Card from "./Card.svelte";
	import Carousel from "./Carousel.svelte";
	import resize from "svelte-actions-resize";
	import displayedCardCount from "../lib/displayedCardCount";

	export let cards;
	export let style;
	export let width;
	export let rtl;
	export let imagesHaveBorders = false;
	export let carouselArrowButtonY;
	export let analytics;
	export let ySpaceBetweenCards = "2px";

	let clientWidth;
	let showRight;
	let currentSlide = 0;
	let cardWidth = "";
	let maxHeight = 0;

	$: {
	  maxHeight = 0;
	}

	const dispatch = createEventDispatcher();

	afterUpdate(() => {
	  updateCardWidth();
	});

	function updateCardWidth() {
	  const scale = clientWidth <= 300 ? 0.86 : 0.95;
	  showRight = showRightIcon();
	  cardWidth =
	    Math.ceil((clientWidth * scale) / displayedCardCount(clientWidth)) -
	    6 +
	    "px";
	}

	function handleSlideChange(event) {
	  currentSlide = event.detail.currentSlide;
	  showRight = showRightIcon();
	  recordSwipe(event);
	}

	function recordSwipe(event) {
	  if (analytics) {
	    if (!currentSlide || event.detail.currentSlide > currentSlide) {
	      analytics.registerGenericEvent("carouselRight");
	    } else {
	      analytics.registerGenericEvent("carouselLeft");
	    }
	  }
	}

	function handleOpenURL(event) {
	  dispatch("openUrl", event.detail);
	}

	function showRightIcon() {
	  const carouselWidth = parseInt(cardWidth) * cards.length;
	  const isWideEnough = carouselWidth >= clientWidth;
	  return (
	    isWideEnough &&
	    currentSlide < cards.length - displayedCardCount(clientWidth)
	  );
	}

	function handlePostback(event) {
	  dispatch("postback", event.detail);
	}

	function handleCardResize(event) {
	  maxHeight = Math.max(maxHeight, event.detail);
	}
</script>

<div class="carousel-container"
		bind:clientWidth={clientWidth}
		style={`
			width: ${(clientWidth < 300) ? '86%' : '98%'}
		`}
		 use:resize
		 on:resize={updateCardWidth}
	 >
	{#if cardWidth}
	  <Carousel
	      perPage={displayedCardCount(clientWidth)}
	      duration={250}
	      loop={false}
	      dots={false}
	      on:change={handleSlideChange}
				draggable={cards.length > 1}
				analytics={analytics}
				arrowButtonY={carouselArrowButtonY}
	    >
	      <div class="control left-arrow" slot="left-control">
	        {#if currentSlide !== 0 && cards.length > 1}
						<div class="icon-wrapper">
	          	<ChevronLeftIcon />
						</div>
	        {/if}
	      </div>
			    {#each cards as card}
			      <div class="slide-content">
			        <Card
						width={width}
								styles={style}
								margin={"0 12px 0 0"}
			          src={card.src}
			          text={card.text}
			          headline={ card.headline }
								rtl={rtl}
								minHeight={maxHeight}
			          subHeadline={card.subHeadline}
			          buttons={card.buttons}
								imagesHaveBorders={imagesHaveBorders}
								on:postback={handlePostback}
								on:openUrl={handleOpenURL}
								on:instruction={(event) => dispatch('instruction', event.detail)}
								on:resize={handleCardResize}
								ySpaceBetweenCards={ySpaceBetweenCards}
			        />
			      </div>
			    {/each}
			<span class="control" slot="right-control">
	      {#if showRight}
					<div class="icon-wrapper">
	        	<ChevronRightIcon />
			</div>
	      {/if}
	  </Carousel>
	{/if}
</div>
<style>
	.slide-content {
	  margin-bottom: 1px;
	}
	.carousel-container {
	  position: relative;
	  overflow: visible;
	}
	.icon-wrapper {
	  height: 32px;
	  width: 32px;
	  background-color: white;
	  border-radius: 50%;
	  display: inline-block;
	  position: relative;
	}
	.control :global(svg) {
	  width: 20px;
	  height: 20px;
	  cursor: pointer;
	  margin: 0;
	  position: absolute;
	  top: 50%;
	  -ms-transform: translate(-50%, -50%);
	  transform: translate(-50%, -50%);
	}
	:global(.right) {
	  margin-right: -40px;
	}
</style>
