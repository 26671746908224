<script>
  import IconButton from './IconButton.svelte'
  import { createEventDispatcher, onDestroy, afterUpdate } from 'svelte';

  const dispatch = createEventDispatcher();

  export let style;
  export let inputConfig;

  let textValue = inputConfig.promptCopy;
  let hasFocus = false;
  let textHasBeenSubmitted = false;
  let lastTextValue = '';

  function textChangeHandler(event){
    lastTextValue = textValue
    dispatch('textChange', textValue);
  }

  function submitHandler(event){
    submitText()
  }

  function submitText(){
    const valueToSubmit = textValue;
    if(valueToSubmit.trim().length){
      textHasBeenSubmitted = true
      dispatch('textSubmit', valueToSubmit.trim());
      textValue = '';
    }
  }

  function blurHandler(){
    if(textValue === '' && !textHasBeenSubmitted){
      textValue = inputConfig.promptCopy
    }
    hasFocus = false;
  }

  function focusHandler(){
    hasFocus = true;
    if(textValue === inputConfig.promptCopy){
      textValue = ''
    }
  }

  function handleKeydown(event)  {
    if(!hasFocus) return null;
    let char = (typeof event !== 'undefined') ? event.keyCode : event.which
    if(char == 13 && textValue.length > 0){
      submitText()
    }
  }

	onDestroy(() => document.onkeypress = null);


</script>
<svelte:window on:keydown={handleKeydown}/>
<div id="input-container" style={`
    width: ${inputConfig.textInputWidth};
  `}>
  <div id="text-input-container">
    <div id="text-input-wrapper"
          style={`border-color: ${style.textInputColor};
                  border-radius: ${style.textInputBorderRadius || "14px"}
          `}
    >
      <input
        style={`
          background-color: rgba(1,1,1,0);
          color: ${style.textInputColor};
        `}
        type="text"
        bind:value={textValue}
        on:input="{textChangeHandler}"
        on:focus={focusHandler}
        on:blur={blurHandler}
      />
    </div>
  </div>
  <div id="send-button-container">
    <IconButton
      enabled={textValue.length !== 0}
      styles={style}
      on:click={submitHandler}
    />
  </div>
</div>

<style>

  #input-container{
    position: relative;
    display: flex;
    margin: 0 auto;
    width: 100%;
  }

  #send-button-container{
    width: 22px;
    position: relative;
    margin: 0 auto;
  }
  #text-input-container{
    flex-grow: 1;
    -ms-flex-positive: 1; /* IE 10 */
    border: 15px solid rgba(1,1,1,0);
    border-left: 0;
  }
  #text-input-wrapper{
    width: 100%;
    height: 100%;
    align-items:center;
    display: flex;
    border: 1px solid;
    border-radius: 14px;
    background-color: white;
  }
  input{
    width: 100%;
    margin: 0 6px;
    outline: none;
    border: none;
    height: 30px;
    font-size: 13px;
  }
</style>
