<script>
  import ScrollContainer from "./ScrollContainer.svelte";
  import InputContainer from "./InputContainer.svelte";
  import ChatInterface from "../lib/ChatInterface.js";
  import StaggeredContent from "../lib/StaggeredContent.js";

  import { createEventDispatcher, beforeUpdate, tick } from "svelte";

  export let style;
  export let width;
  export let height;
  export let intentNameCallback;
  export let config;
  export let loadMode = false;
  export let startingPhrase = "";
  export let content = [];
  export let includeInput = true;
  export let submissionPhrase;
  export let startingPhraseWasTyped = false;
  export let carouselArrowButtonY;
  export let ySpaceBetweenCards;
  export let chatTimingMS = 600;
  export let analytics;

  let scrollHeight;
  let clientHeight;
  let hasStarted;
  let isLoading;

  let chatInterface = new ChatInterface();
  let staggeredContent = new StaggeredContent([], chatTimingMS, updateCallback);

  $: {
    submitText(submissionPhrase);
  }

  const dispatch = createEventDispatcher();

  beforeUpdate(() => {
    if (startingPhrase.length !== 0 && !hasStarted) {
      submitText(startingPhrase, startingPhraseWasTyped);
    }
  });

  function textChangeHandler(event) {
    dispatch("textChange", event.detail);
  }

  function handlePostback(event) {
    submitText(event.detail);
  }

  function handleOpenURL(event) {
    dispatch("openUrl", event.detail);
  }

  async function updateCallback(items, turnOffLoadMode) {
    content = items;
    await tick();
    loadMode = !turnOffLoadMode;
  }

  function handleTextSubmit(event) {
    submitText(event.detail, true);
  }

  function handleInstruction(event) {
    dispatch("instruction", event.detail);
  }

  async function submitText(phrase, wasTyped) {
    if (phrase && phrase.length) {
      loadMode = true;
      hasStarted = true;
      staggeredContent.add([
        {
          type: "userResponse",
          text: phrase
        }
      ]);
      isLoading = true;
      dispatch("textSubmit", { phrase: phrase, wasTyped: wasTyped });
      console.log(config.customDimensions);
      const response = await chatInterface.chat(
        phrase,
        config,
        intentNameCallback,
        config.rtl,
        config.customDimensions
      );
      staggeredContent.add(response);
      await tick();
      isLoading = false;
    }
  }
</script>
<div bind:clientHeight class="chat-wrapper">
  <slot name="intro-header">
  </slot>
  <slot name="chat-header">
  </slot>
  <div class="scroll-wrapper">
    <ScrollContainer
      styles={style}
      width={width}
      height={height}
      content={content}
      on:postback={handlePostback}
      on:openUrl={handleOpenURL}
      on:instruction={handleInstruction}
      isLoading={isLoading || loadMode}
      rtl={config.rtl}
      analytics={analytics}
      carouselArrowButtonY={carouselArrowButtonY}
      ySpaceBetweenCards={ySpaceBetweenCards}
    />
  </div>
  {#if includeInput}
    <InputContainer
      style={style}
      inputConfig={config.inputConfig}
      on:textChange={textChangeHandler}
      on:textSubmit={handleTextSubmit}
    />
  {/if}
</div>

<style>
  div {
    position: relative;
  }
  .chat-wrapper {
    height: 100%;
  }
</style>
