
import generateUniqueSessionId from '../../lib/generateUniqueSessionId.js'
const style = {
  brandColor: '#FFFFFF',
  secondaryBrandColor: '#f9485c',
  chatBackgroundColor: '#ccb79c',
  chatBackgroundTile: 'url(../images/woolworth-xmas/seamless-tile.jpg)',
  // cards
  cardBackground: 'white', // the card background
  cardTextColor: '#262626',// cards
  borderRadius: '8px', // cards + buttons
  imagesHaveBorders: true,
  // imagesBorderColor: true, // not working yet
  // Users
  userTextColor: '#ffffff',// user options
  userBackgroundColor: '#f9485c',// user options
  // Text Input
  UIButtonBackgroundColor: '#262626', // textInput send button
  UIButtonStrokeColor: 'white', // textInput send button
  textInputColor: '#675A53', // textInput options
  textInputBorderRadius: '16px', // textInput options
}

const config = {
  url: 'https://adchat.web.app',
  // url: 'http://localhost:3001',
  productionMode: true,
  landerBackgroundImage: 'url(../images/woolworth-xmas/bg-image.png)',
  language: 'EN',
  headerImage: "url('./images/woolworth-xmas/header-logo-icon.jpg'),url('./images/woolworth-xmas/text-hashtag.png'), linear-gradient(90deg, rgba(176,147,94,1) 0%, rgba(255,221,154,1) 25%, rgba(156,128,79,1) 50%, rgba(200,168,106,1) 75%, rgba(155,127,78,1) 100%);",
  headerBackgroundPosition: '16px 50%, calc(100% - 16px) 50%, 0 0',
  headerBackgroundColor: '#f9485c',
  chatHeightOffset: 80,
  ySpaceBetweenCards: '6px',
  sessionId: generateUniqueSessionId(),
  platform: 'landing-page-en',
  hasInput: false,
  version: '1.0',
  projectId: 'woolworth-xmas-social',
  inputConfig: {
    textInputWidth: '80%',
    promptCopy: "Ask me about gift ideas"
  },
  firstFrame: {
    inputConfig: {
      textInputWidth: '80%',
      promptCopy: "Ask me about gift ideas"
    },
  },
  exits: {
  },
  simulatedConversation: [
    {
      type: 'brandResponse',
      text: `Hi! 👋 🧝 I’m your personal #WooliesChristmas elf, here to guide you through every festive moment!`
    },
    {
      type: 'brandResponse',
      text: `Want help decorating your home or picking a special gift?`
    },
    {
      type: 'card',
      text: `Select one to get started. 👇`,
      buttons: [
        {title: 'Get ready to host 🎉'},
        {title: 'Find a special gift 🎁'},
      ]
    }

  ]
}

export default {
  style: style,
  config: config,
}
