<script>
  import { createEventDispatcher } from 'svelte';

  export let label;
  export let styles;
  export let url;
  export let postback;
  export let rtl;
  export let isHovering = false;

  const dispatch = createEventDispatcher();

  function clickHandler(event){
    event.preventDefault();
    if(url && url.length){
      dispatch('openUrl', url)
      return
    }
    let expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
    let regex = new RegExp(expression);
    if(postback.match(regex)){
      dispatch('openUrl', postback)
    } else {
      dispatch('postback', postback)
    }
  }

</script>

<div class="button-container">
  <div style="{`
    		color: ${ styles.brandColor };
    		background-color: ${ styles.secondaryBrandColor };
    		border: ${styles.border || 'inherit'};
        border-radius: ${styles.borderRadius || '8px'};
        opacity: ${(isHovering) ? 0.7 : 1};
      `}"
      class="button"
      on:click={clickHandler}
      on:mouseenter={() => isHovering = true}
      on:mouseleave={() => isHovering = false}
    >
    <a href={'#'} class={(rtl) ? 'rtl' : ''}
      style={`color: ${ styles.brandColor };
      `}
   >
   {#if label !== undefined}
      {label}
    {/if}
  </a>
  </div>
</div>
<style>

  a{
    text-decoration: none;
  }
  .button{
    width: 85%;
    display: inline-block;
    margin: 8px auto 0 auto;
    padding: 6px 10px;
    font-size: 14px;
    font-weight: 500;
    background-color: white;
    cursor: pointer;
  }
  .button-container{
    text-align: center;
    max-width: 200px;
    margin: 0 auto;
  }

</style>
