<script>
  import Image from "./Image.svelte";
  import SimpleButton from "./SimpleButton.svelte";
  import { createEventDispatcher, afterUpdate, tick } from "svelte";
  import snarkdown from "snarkdown";

  export let styles;
  export let src;
  export let text;
  export let headline;
  export let subHeadline;
  export let buttons;
  export let rtl;
  export let minHeight = "";
  export let isSquare = false;
  export let width = null;
  export let margin = "0 12px";
  export let imagesHaveBorders = false;
  export let cardWidth;
  export let ySpaceBetweenCards = "2px";

  let bgColor = headline || text || buttons ? styles.cardBackground : null;
  let hasLoaded = !src;
  let clientHeight;
  let maxWidth = 0;
  let buttonTopMargin = (minHeight ? minHeight - clientHeight - 24 : 0) + "px";
  let idValue = "card-" + Math.random() * 100000;
  let shouldClick = true;
  const dispatch = createEventDispatcher();

  afterUpdate(() => {});

  async function imageLoadedHandler(event) {
    hasLoaded = true;
    await tick();
    if (document.getElementById(idValue))
      clientHeight = document.getElementById(idValue).clientHeight;
    dispatch("resize", clientHeight);
  }

  function buttonPostbackHandler(event) {
    if (event.detail.indexOf("instruction:") !== -1) {
      dispatch("instruction", event.detail.split("instruction:")[1]);
    } else {
      dispatch("postback", event.detail);
    }
  }

  function buttonOpenUrlHandler(event) {
    dispatch("openUrl", event.detail);
  }
</script>
<div class="card"
  id={idValue}
  style={`
    overflow: hidden;
    width: ${width};
    margin: ${margin};
    ${(styles.cardBorder) ? `border:  ${styles.cardBorder};` : ""}
    max-width: ${(cardWidth)?cardWidth:'initial'};
    border: ${(imagesHaveBorders) ? '1px solid ' + styles.cardBackground : null };
    background-color: ${bgColor};
    opacity: ${(hasLoaded) ? '1' : '0.01'};
    height: ${(minHeight && clientHeight) ? minHeight + 'px': null};
    margin-bottom: ${ySpaceBetweenCards};
  `
  }
  >
  {#if src}
    <div class="image-wrapper">
      <Image
        src={src}
        on:loaded={imageLoadedHandler}
        height={(isSquare) ? 573 : 300}
      />
    </div>
  {/if}
  {#if headline || subHeadline || text }
    <div
      class="copy-wrapper"
      style={`
          padding-bottom: ${(headline && (subHeadline || src )) ? '8px' : null};
          color: ${(styles.cardTextColor) ? styles.cardTextColor : styles.secondaryBrandColor};
        `}>
      {#if headline}
        <h5 class={`headline ${(rtl) ? 'rtl' : ''}`}>
          { @html snarkdown(headline) }
        </h5>
      {/if}
      {#if subHeadline}
        <h6 class={`sub-headline ${(rtl) ? 'rtl' : ''}`}>
          { @html snarkdown(subHeadline) }
        </h6>
      {/if}
      {#if text}
        <p class={`${(rtl) ? 'rtl' : ''}`}
          style={`margin: ${(!src) ? '2px 0' : '0' }`}
        >
          { @html snarkdown(text) }
        </p>
      {/if}
    </div>
  {/if}

    {#if buttons && buttons.length}
      <div class="card-button-wrapper">
        {#each buttons as button}
          <SimpleButton
            label={button.title}
            styles={styles}
            postback={button.postback || button.title}
            url={button.url || null}
            on:postback={buttonPostbackHandler}
            on:openUrl={buttonOpenUrlHandler}
            rtl={rtl}
          />
        {/each}
      </div>
  {/if}

</div>

<style>
  .black {
    color: black;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .image-wrapper {
    overflow: hidden;
  }
  .copy-wrapper {
    flex-grow: 1;
  }
  .card-button-wrapper {
    padding-bottom: 8px;
  }
  .card {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    border-radius: 8px;
    font-weight: 400;
    line-height: 18px;
    min-width: 212px;
    position: relative;
  }
  .headline {
    font-size: 13px;
    font-weight: 500;
    padding: 0 8px 0 8px;
    margin: 4px 0;
    line-height: 16px;
  }
  .sub-headline {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    padding: 0 8px 0 8px;
    margin: 0;
    white-space: pre-wrap;
  }
  p {
    padding: 8px 8px 0 8px;
    margin: 0;
  }
</style>
