<script>
  import ChatComponent from "../components/ChatComponent.svelte";
  import SimulatedConversation from "../components/SimulatedConversation.svelte";
  import InputContainer from "../components/InputContainer.svelte";
  import URLToExitMap from "../lib/URLToExitMap";
  import { onMount } from "svelte";
  import Analytics from "../lib/Analytics";
  import displayedCardCount from "../lib/displayedCardCount";

  export let style;
  export let config;
  let height = "100%";
  let width = "100%";
  let challenge = "";
  let docId = "";
  let conversationMode = false;
  let startingPhrase = "";
  let submissionPhrase;
  let lastTypedQuestion = "";
  let startingPhraseWasTyped = false;
  let hasEngaged = false;
  let hash;
  let clientHeight;
  let hasClosedOnce = false;
  let trackingName = "";
  let innerWidth;
  let cardWidth;

  const exits = new URLToExitMap(config.exits);
  const analytics = new Analytics(config);

  onMount(() => {
    analytics.registerImpression();
  });

  function handlePostback(event) {
    if (!hasEngaged) {
      hasEngaged = true;
    }
    startingPhrase = event.detail;
    conversationMode = true;
    setTimeout(() => {
      hasClosedOnce = true;
    }, 250);
  }

  function handleChatSubmit(event) {
    lastTypedQuestion = event.detail.wasTyped ? event.detail.phrase : "";
    conversationMode = true;
  }

  function handleUserSubmissionComplete(event) {}

  function handleTextChange() {}

  function handleTextSubmit(event) {
    startingPhrase = event.detail;
    lastTypedQuestion = startingPhrase;
    startingPhraseWasTyped = true;
    conversationMode = true;
  }

  function handleOpenURL(event) {
    const url = event.detail.replace("utm_medium=display", "utm_medium=social");
    const clickTag = config.clickTag || "";
    if (!exits.exits) {
      trackExit(url, config.sessionId);
      window.open(clickTag + url, "_blank");

      return;
    }
    trackExit(url, config.sessionId);
    const exitName = exits.getMapping(url);
    if (!exitName || typeof Enabler === "undefined") {
      window.open(clickTag + url, "_blank");
    } else {
      Enabler.exit(exitName, url);
    }
  }

  function trackExit(url, sessionId) {
    const language = config.language === "en" ? "en" : "fr";
    const http = new XMLHttpRequest();
    const base = config.url + `/chat/${config.projectId}.json`;
    const platform = config.platform ? config.platform : "default";
    const version = config.version ? config.version : "";
    const params = `?platform=${platform}&version=${version}&session=${sessionId}&url=${encodeURIComponent(
      url
    )}`;
    http.open("POST", base + params, true);
    http.onreadystatechange = function() {
      if (http.readyState == 4 && http.status == 200) {
        return http.responseText;
      }
    };
    http.send(params);
  }

  function intentNameCallback(intentName) {
    trackingName = intentName;
  }

  function handleInstruction() {}
</script>
<svelte:window bind:innerWidth={innerWidth}/>

<div class="wrapper">
  {#if config.hasHeader !== false}
    <div class="header-wrapper">
      <div class="header"
        style={`
          background-image: ${config.headerImage};
          ${(config.headerBackgroundRepeat) ? `background-repeat: ${config.headerBackgroundRepeat};` : ''};
          background-position: ${(config.headerBackgroundPosition) ? config.headerBackgroundPosition : '50% 50%'};
          background-size: ${(config.headerBackgroundSize) ? config.headerBackgroundSize : ''};
          background-color: ${(config.headerBackgroundColor) ? config.headerBackgroundColor : ''};
        `}
        >
        {#if config.headerTitle || config.headerSubTitle}
          <div class="header-icon-wrapper">
            {#if config.headerTitle}
              <h1>{config.headerTitle}</h1>
            {/if}
            {#if config.headerSubTitle}
              <h2>{config.headerSubTitle}</h2>
            {/if}
          </div>
        {/if}
      </div>
    </div>
  {/if}
  <div class="container" bind:clientHeight={clientHeight}
  >
    <div class="chat-container"
      style={`
        ${(style.chatBackgroundTile) ? 'background-image:' + style.chatBackgroundTile + ';': '' }
        background-color: ${(style.chatBackgroundColor) ? style.chatBackgroundColor : 'white'};
      `}>
      {#if clientHeight && conversationMode}
        <ChatComponent
          style={style}
          width={width}
          height={(clientHeight - config.chatHeightOffset) + 'px'}
          config={config}
          startingPhrase={startingPhrase}
          includeInput={config.hasInput}
          intentNameCallback={intentNameCallback}
          submissionPhrase={submissionPhrase}
          on:instruction={handleInstruction}
          on:textSubmit={handleChatSubmit}
          on:openUrl={handleOpenURL}
          chatTimingMS={config.chatTimingMS}
          analytics={analytics}
          ySpaceBetweenCards={config.ySpaceBetweenCards}
        >
        </ChatComponent>
      {:else}
        <div class="background-container" style={`
          background-image: ${config.landerBackgroundImage};
        `}></div>
      {/if}
    </div>

    <div class={(conversationMode) ?
      (!hasClosedOnce) ? "simulation-container animate__animated animate__fadeOutDown" : "hidden" : "simulation-container"}
        style={`
          height: calc(100% - ${config.inputConfig.bottomMargin});
        `}
      >
      <div class="simulation">
        <SimulatedConversation
          hasInput={config.hasInput}
          style={style}
          height={(clientHeight - config.chatHeightOffset) + 'px'}
          content={config.simulatedConversation}
          width='100%'
          on:postback={handlePostback}
          rtl={config.rtl}
          ySpaceBetweenCards={config.ySpaceBetweenCards}
        />
        {#if (!conversationMode) && config.hasInput}
          <InputContainer
            style={style}
            inputConfig={config.firstFrame.inputConfig}
            on:textChange={handleTextChange}
            on:textSubmit={handleTextSubmit}
          />
        {/if}
      </div>
    </div>
  </div>

</div>
<style>
  :global(body) {
    padding: 0;
    font-weight: 400;
  }
  .container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    max-width: 874px;
    margin: 0 auto;
  }
  .chat-container,
  .simulation-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .simulation {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .simulation-container {
    height: calc(100% - 80px);
  }
  .hidden {
    display: none;
  }
  .header-icon-wrapper {
    margin-left: calc(8% + 50px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .header-wrapper {
    max-width: 874px;
    margin: 0 auto;
  }
  .header {
    width: 100%;
    height: 80px;
    background-repeat: no-repeat;
    position: relative;
    background-color: white;
  }

  .header h1 {
    color: black;
    font-size: 15px;
    font-weight: bold;
    margin: 0;
  }

  .header h2 {
    color: #5b7083;
    font-size: 15px;
    font-weight: normal;
    margin: 0;
  }

  .header button {
    background-color: #0066b1;
    color: white;
    font-weight: bold;
    border: 1px solid #0066b1;
    padding: 5px 12px;
    border-radius: 8px;
    position: absolute;
    top: 13px;
    right: 12px;
  }
  .header button:hover {
    color: #0066b1;
    background-color: white;
  }
  .animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }
  .wrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .background-container {
    background-repeat: no-repeat;
    background-position: center 0;
    background-size: contain;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  @keyframes fadeOutDown {
    from {
      opacity: 1;
    }

    to {
      opacity: 0;
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
    }
  }
  .animate__fadeOutDown {
    -webkit-animation-name: fadeOutDown;
    animation-name: fadeOutDown;
  }
</style>
