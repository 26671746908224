<script>
  import ConversationContainer from "./ConversationContainer.svelte";
  import StaggeredContent from "../lib/StaggeredContent.js";
  import { createEventDispatcher, tick } from "svelte";

  export let height;
  export let width;
  export let content = [];
  export let style;
  export let rtl = false;
  export let hasInput = true;
  export let ySpaceBetweenCards = "2px";
  export let chatTimingMS = 1600;
  export let analytics;

  let dispatch = createEventDispatcher();
  let loadMode = true;
  let staggeredContent = new StaggeredContent(
    [],
    chatTimingMS,
    updateCallback,
    true
  );
  let simulatedContent = [];
  staggeredContent.add(content);

  function handlePostback(event) {
    dispatch("postback", event.detail);
  }
  function handleOpenURL(event) {
    dispatch("openUrl", event.detail);
  }

  async function updateCallback(items, turnOffLoadMode) {
    loadMode = !turnOffLoadMode;
    simulatedContent = items;
  }
</script>
<div class='simulated-conversation'
  style={`
    padding-bottom: ${(!hasInput) ? '8px' : '0'}
  `}
>
  <ConversationContainer
    styles={style}
    width={width}
    height={height}
    isLoading={loadMode}
    content={simulatedContent}
    on:postback={handlePostback}
    on:openUrl={handleOpenURL}
    rtl={rtl}
    ySpaceBetweenCards={ySpaceBetweenCards}
    chatTimingMS={chatTimingMS}
    analytics={analytics}
  />
</div>
