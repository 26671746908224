<style>
  .container {
    width: 100%;
  }
</style>

<script>
  import SvelteSimplebar from "../vendor/SvelteSimplebar.svelte";
  import ConversationContainer from "./ConversationContainer.svelte";
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let content;
  export let height;
  export let width;
  export let styles;
  export let isLoading;
  export let rtl;
  export let carouselArrowButtonY;
  export let ySpaceBetweenCards;
  export let analytics;

  let svelteBar;

  const initBar = bar => {
    // console.log(bar);
  }

  function handlePostback(event){
    dispatch('postback',event.detail)
  }

  function handleOpenURL(event){
    dispatch('openUrl', event.detail)
  }

  function handleInstruction(event){
    dispatch('instruction', event.detail);
  }


</script>

<div class="container"
    style={`
      height: ${height};
      width: ${width};
      position: relative;
  `}>
  <SvelteSimplebar init={initBar}>
    <ConversationContainer
      styles={styles}
      width="100%"
      content={content}
      on:postback={handlePostback}
      on:openUrl={handleOpenURL}
      on:instruction={handleInstruction}
      isLoading={isLoading}
      carouselArrowButtonY={carouselArrowButtonY}
      rtl={rtl}
      ySpaceBetweenCards={ySpaceBetweenCards}
      analytics={analytics}
    />
  </SvelteSimplebar>
</div>
