<!-- https://svelte.dev/repl/26ba12b3fbd146eaaefc8b024a826da7?version=3.5.1 -->
<script>
	import { createEventDispatcher, afterUpdate } from 'svelte';
	import { fade } from 'svelte/transition';

	const dispatch = createEventDispatcher();
	const loaded = new Map();

	let visible = false;

  let w = 0
  let h = 0
  export let src;
	export let alt  = '';
	export let width = 0;
	export let height = 0;

	let clientWidth;
	let hasLoaded = false;

	function lazy(node, data) {
		if (!loaded.has(data.src)) {
			const img = new Image();
			img.src = data.src;
			img.onload = () => {
				loaded.set(data.src, img);
				node.setAttribute('src', data.src);
        width = img.width;
        height = img.height;
				hasLoaded = true;
				dispatch('loaded');
			};
		}
    node.setAttribute('src', data.src);

		return {
			destroy(){} // noop
		};
	}
</script>
	<div
			bind:clientWidth
		>
		<img
			class="image"
			width={`${width}px`}
			height={`${height}px`}
			alt={alt || ' '}
	    style={`
	      height: auto;
				width: 100%;
				display: none;
	    `}
	    use:lazy="{{src: `${src}`}}"
		>

		{#if hasLoaded}
			<img
			 	transition:fade
				alt={alt || ' '}
				class="image"
				width={`${width}px`}
				height={`${height}px`}
		    style={`
		      height: auto;
					width: 100%;
		    `}
		    use:lazy="{{src: `${src}`}}"
			>
		{/if}
	</div>

<style>
	div{
		width: 100%;
		height: auto;
	}
  img{
    margin: 0 auto;
    padding: 0;
		display: block;
		overflow: hidden;
		width: 100%;
		height: auto;
  }
</style>
