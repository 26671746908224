export default class StaggeredContent{

  constructor(content = [], interval = 100, updateCallback, addDelay = false){
    this.interval = interval;
    this.masterList = content;
    this.updateCallback = updateCallback;
    this.addDelay = addDelay;
  }

  add(items, time, callback){
    const offset = (this.addDelay)  ? 1 : 0
    items.forEach((item, count) => {
      setTimeout(() => {
        this.masterList.push(item);
        let turnOffLoadMode = (count >= (items.length - 1));
        this.updateCallback(this.masterList, turnOffLoadMode);
      }, (this.interval * (count + offset)))
    })
  }

}
