<script>
  import TypingIndicator from "../components/TypingIndicator.svelte";
  import BrandResponse from "./BrandResponse.svelte";
  import UserResponse from "./UserResponse.svelte";
  import CardCarousel from "./CardCarousel.svelte";
  import Card from "./Card.svelte";
  import { tick, afterUpdate, createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let width;
  export let styles;
  export let content = [];
  export let isLoading = false;
  export let rtl = false;
  export let carouselArrowButtonY;
  export let ySpaceBetweenCards;
  export let analytics;

  let conversationHeight;
  let clientWidth;
  let clientHeight;
  let perPage = { 800: 3, 500: 2, 300: 1 };
  console.log("rtl " + rtl);

  // 1: 87%, 2: 43%, 3: 76%
  let wrapperWidth = clientWidth <= 300 ? "100%" : "90%";

  const supportsNativeSmoothScroll =
    "scrollBehavior" in document.documentElement.style;

  function isLast(index, isUser) {
    const nextContent = content[index + 1];
    if (!nextContent) return false;
    const nextIsBrand = nextContent.type !== "userResponse";
    if (isUser && !nextIsBrand) {
      return false;
    }
    if (!isUser && nextIsBrand) {
      return false;
    }
    return true;
  }

  function calculateCardWidth() {
    let count = 0;
    let found = false;
    Object.keys(perPage)
      .reverse()
      .forEach(breakpoint => {
        if (clientWidth >= breakpoint && !found) {
          found = breakpoint;
        }
        count++;
      });
    const cardsPerPage = perPage[found];
    return (clientWidth / cardsPerPage) * 0.98;
  }

  function handlePostback(event) {
    dispatch("postback", event.detail);
  }

  function handleInstruction(event) {
    dispatch("instruction", event.detail);
  }

  function handleOpenURL(event) {
    dispatch("openUrl", event.detail);
  }

  async function cardResizeHandler(event) {
    const content = document.getElementsByClassName(
      "simplebar-content-wrapper"
    )[0];
    if (content) {
      await tick(); // wait until the image is in the dom
      if (content) scrollToElem(content, clientHeight);
      // content.scrollTop = clientHeight;
    }
  }

  afterUpdate(() => {
    const content = document.getElementsByClassName(
      "simplebar-content-wrapper"
    )[0];
    const conversationHeight = document.getElementsByClassName("holder")[0]
      .clientHeight;
    if (content && conversationHeight < content.clientHeight) {
      document.getElementsByClassName("holder")[0].style.marginTop =
        content.clientHeight - conversationHeight + "px";
    } else {
      document.getElementsByClassName("holder")[0].style.marginTop = null;
    }
    if (content) scrollToElem(content, clientHeight);
    // content.scrollTop = clientHeight;
  });

  // native smooth scrolling for Chrome, Firefox & Opera
  // @see: https://caniuse.com/#feat=css-scroll-behavior
  const nativeSmoothScrollTo = (elem, top) => {
    elem.scroll({
      behavior: "smooth",
      left: 0,
      top: top
    });
  };

  // polyfilled smooth scrolling for IE, Edge & Safari
  const smoothScrollTo = (element, to, duration) => {
    const //element = document.scrollingElement || document.documentElement,
      start = element.scrollTop,
      change = to - start,
      startDate = +new Date();

    // t = current time
    // b = start value
    // c = change in value
    // d = duration
    const easeInOutQuad = (t, b, c, d) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    animateScroll = _ => {
      const currentDate = +new Date();
      const currentTime = currentDate - startDate;
      element.scrollTop = parseInt(
        easeInOutQuad(currentTime, start, change, duration)
      );
      if (currentTime < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        element.scrollTop = to;
      }
    };
    animateScroll();
  };

  // detect support for the behavior property in ScrollOptions

  // smooth scrolling stub
  const scrollToElem = (elemSelector, top) => {
    if (!elemSelector) {
      return;
    }

    const elem = elemSelector;
    if (elem) {
      if (supportsNativeSmoothScroll) {
        nativeSmoothScrollTo(elem, top);
      } else {
        smoothScrollTo(elemSelector, top, 600);
      }
    }
  };
</script>
<div class="holder"
    bind:clientWidth
    bind:clientHeight
    style={`
      padding-right: ${styles.paddingRight};
      width: ${width}`
    }
  >
  {#each content as item, index}
    <div class="item">
      {#if item.type === 'userResponse'}
        <UserResponse
          style={styles}
          text={item.text}
          hasBottomMargin={isLast(index, true)}
          rtl={rtl}
          ySpaceBetweenCards={ySpaceBetweenCards}
        />
      {/if}
      {#if item.type === 'carousel'}
        <div class="carousel-wrapper"
          style={`margin-left: 12px;
                  width: ${wrapperWidth};`}
        >
          <CardCarousel
            style={styles}
            cards={item.items}
            perPage={perPage}
            rtl={rtl}
            on:openUrl={handleOpenURL}
            on:postback={handlePostback}
            on:instruction={handleInstruction}
            carouselArrowButtonY={carouselArrowButtonY}
            ySpaceBetweenCards={ySpaceBetweenCards}
            analytics={analytics}
          />
        </div>
      {/if}
      {#if item.type === 'brandResponse'}
        <BrandResponse
          styles={styles}
          text={item.text}
          width={item.width}
          hasBottomMargin={isLast(index, false)}
          rtl={rtl}
          ySpaceBetweenCards={ySpaceBetweenCards}
        />
      {/if}
      {#if item.type === 'card'}
        <div class="carousel-wrapper"
          style={`margin-left: 12px;
                  width: ${wrapperWidth};`
              }
        >
          <CardCarousel
            style={styles}
            cards={[item]}
            perPage={perPage}
            rtl={rtl}
            on:openUrl={handleOpenURL}
            on:postback={handlePostback}
            on:instruction={handleInstruction}
            ySpaceBetweenCards={ySpaceBetweenCards}
            analytics={analytics}
            width={item.width}
          />
        </div>
      {/if}

    </div>
  {/each}
  {#if isLoading }
    <div class="clearfix">
      <TypingIndicator
        styles={styles}
      />
    </div>
  {/if}
</div>
<style>
  .item {
    width: 100%;
    clear: both;
  }
  .holder {
    overflow: hidden;
  }
</style>
