class Analytics{

    constructor(config){
      this.config = config
    }

    registerImpression(){
      const config = this.config
      const url = `${config.url}/count/${config.projectId}.json?platform=${config.platform}&session=${config.sessionId}`
      const http = new XMLHttpRequest();
      http.open('GET', url);
      http.send()
    }

    registerGenericEvent(eventName){
      const config = this.config
      const url = `${config.url}/generic/${config.projectId}.json?platform=${config.platform}&eventName=${eventName}&session=${config.sessionId}`
      const http = new XMLHttpRequest();
      http.open('GET', url);
      http.send()
    }
}
export default Analytics;
