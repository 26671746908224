<script>
  import TypingIndicator from "./TypingIndicator.svelte";
  import snarkdown from "snarkdown";

  export let text;
  export let styles;
  export let hasBottomMargin;
  export let rtl = false;
  export let width;
  export let ySpaceBetweenCards = "2px";
</script>
  <div class="user-bubble user-bubble-last" style={`
      background-color: ${styles.cardBackground};
      margin-bottom: ${(hasBottomMargin) ? '8px' : ySpaceBetweenCards};
      ${(styles.cardBorder) ? "border: " + styles.cardBorder + ";" : ""}
      color: ${(styles.cardTextColor ? styles.cardTextColor : styles.secondaryBrandColor)};
      direction: ${(rtl) ? 'rtl' : ''};
      width: ${(width) ? width : 'auto'};
      max-width: ${(parseInt(width) > 80) ? width : '80%'};
    `}>{@html snarkdown(text)}</div>
<style>
  .user-bubble-last {
    clear: both;
    margin-bottom: 8px;
  }
  .user-bubble {
    white-space: pre-wrap;
    margin: 0px 12px 2px 12px;
    padding: 8px 12px;
    display: inline-block;
    border-radius: 8px;
    font-weight: 400;
    /* line-height: 18px; */
    /* color:  #252525; */
  }
</style>
