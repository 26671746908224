import BaseSocialView from '../views/BaseSocialView.svelte'
import data from '../projects/data/woolworths-xmas-social.js'

const app = new BaseSocialView({
	target: document.body,
	props: {
    style: data.style,
		config: data.config,
	}
});

export default app;
