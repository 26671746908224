<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let enabled = false;
  export let styles;
  let rotation = 0;

  function onClick(event) {
    if(enabled){
      dispatch('click', event);
    }
  }

  function onMouseOver(event){
    if(enabled){
      rotation = 90;
    }
  }

  function onMouseOut(event){
    rotation = 0;
  }

</script>
<div
  on:click={onClick}
  on:mouseover={onMouseOver}
  on:mouseout={onMouseOut}
  class={(enabled) ? 'enabled' : 'disabled'}
  style={
  ` transform: translate(-50%, -50%) rotate(${rotation}deg);
    background-color: ${(enabled) ? styles.UIButtonBackgroundColor || styles.textInputColor : styles.cardBackground};
    stroke: ${(enabled) ? styles.UIButtonStrokeColor || styles.cardBackground : styles.textInputColor};;`
  }>
  <svg version="1.1" x="0px" y="0px" viewBox="0 0 91.3 88.5" style="width: 10px; height: 10px; position: absolute; top: 6px; left: 6px;">
    <line x1="45.7" y1="7" x2="45.7" y2="81.5"></line>
    <line x1="45.7" y1="7" x2="84.3" y2="45.7"></line>
    <line x1="45.7" y1="7" x2="7" y2="45.7"></line>
  </svg>
</div>

<style>
div{
  border-radius: 50px;
  width: 22px;
  height: 22px;
  margin: 0px;
  position: absolute;
  top: 50%;
  left: 50%;
}
line{
  stroke-width: 14px;
  stroke-linecap: round;
}
.enabled{
  cursor: pointer;
}
.disabled{
  cursor: default;
}
</style>
