<script>
  import { afterUpdate } from 'svelte';
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let text;
  export let hasBottomMargin;
  export let style;
  export let rtl = false;
  export let ySpaceBetweenCards = '2px';

  let clientHeight;

  afterUpdate(() => {
    const height = clientHeight;
    dispatch('measureComplete', height)
  })
</script>

<div bind:clientHeight class="user-bubble user-bubble-last" style={`
    background-color: ${style.userBackgroundColor || style.brandColor};
    color: ${style.userTextColor ||   style.cardBackground};
    margin-bottom: ${(hasBottomMargin) ? '8px' : ySpaceBetweenCards};
    direction: ${(rtl) ? 'rtl' :  ''};
  `}>
  { text }
</div>
<div style="clear: both;"/>

<style>
  .user-bubble-last {
    margin-bottom: 0;
  }
  .user-bubble {
    float: right;
    margin: 0px 12px 2px 12px;
    padding: 8px 12px;
    max-width: 70%;
    /* display: inline-block; */
    border-radius: 8px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 6px;
    margin-top: 4px;
  }
</style>
