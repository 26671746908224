export default class DialogFlowTransformer{

  transform(dfObject, intentNameCallback = null, rtl = false){
    let { intentName, responses } = dfObject;
    if(intentNameCallback) intentNameCallback(intentName);
    let transformItems = []
    let cards = []
    // for fallback intent
    if(!responses){
      responses = dfObject.messages
    }
    responses.forEach(
      element => {
        const item = this[element.message](element, rtl);
        if(item.length > 0){
          transformItems = transformItems.concat(item)
        } else {
          transformItems.push(item)
        }
      }
    )
    return this.decorateItemsForCarousel(transformItems);
  }

  text(value, rtl){
    return({
      type: 'brandResponse',
      text: value.text.text[0],
      rtl:  rtl,
    });
  }

  quickReplies(value, rtl){
    const quickReplies = {
      type: "card",
      cardForCarousel: false,
      buttons: (value.quickReplies.buttons) ? this.buttonElementsToButtons(value.quickReplies.buttons, rtl) : this.arrayToButtons(value.quickReplies.quickReplies),
      text: (value.quickReplies.title) ? value.quickReplies.title : null,
      rtl:  rtl,

    }
    return quickReplies
  }

  card(value, rtl){
    const card = {
      type: 'card',
      headline: (value.card.title) ? value.card.title : null,
      subHeadline: (value.card.subtitle) ? value.card.subtitle : null,
      cardForCarousel: (value.card.subtitle),
      src: (value.card.imageUri) ? value.card.imageUri : null,
      buttons: (value.card.buttons) ? this.buttonElementsToButtons(value.card.buttons, rtl) : null,
      rtl: rtl,
    }
    return card
  }

  image(value, rtl){
    return({
      type: 'card',
      cardForCarousel: false,
      src: value.image.imageUri,
      rtl: rtl
    })
  }

  payload(value, rtl){
    const payload = (value.payload.facebook.attachment.payload);
    if(payload.buttons){
      const components = []
      const buttons = this.buttonElementsToButtons(payload.buttons, rtl)
      // if(payload.text){
      //   components.push({
      //     type: 'brandResponse',
      //     text: payload.text,
      //     rtl: rtl,
      //   })
      // }
      components.push({
        type: 'card',
        buttons: buttons,
        text: (payload.text) ? payload.text : '',
        cardForCarousel: false,
        rtl: rtl,
      })
      return(components)
    }
    return({
      type: 'customPayload',
    });
  }

  arrayToButtons(list){
    const buttons = []
    list.forEach((element, i) => {
      buttons.push({
        title: element,
      })
    });
    return buttons;
  }

  buttonElementsToButtons(buttonElements, rtl){
    const buttons = []
    buttonElements.forEach(element => {
        if(element.type === 'web_url'){
          buttons.push({
            title: element.title,
            url: element.url,
            rtl
          })
        } else {
          buttons.push({
            title: element.text || element.title,
            url: (element.url) ? element.url : null,
            postback: (element.payload) ? element.payload : (element.postback && element.postback.length && !element.url) ? element.postback : null,
            rtl
          })
        }
      }
    )
    return buttons;
  }

  decorateItemsForCarousel(items){
    let newItems = [];
    let cards = [];
    items.forEach(element => {
      if(element.type !== 'card' || element.cardForCarousel === false){
        if(cards.length > 1){
          newItems.push({
            type: 'carousel',
            items: cards,
          })
        }
        if(cards.length === 1){
          newItems = newItems.concat(cards)
        }
        cards = []
        newItems.push(element);
      } else {
        cards.push(element)
      }
    })
    if(cards.length > 1){
      newItems.push({
        type: 'carousel',
        items: cards,
      })
    }
    if(cards.length === 1){
      newItems = newItems.concat(cards)
    }
    return newItems;
  }

}
